.setting-container {
  padding: 15px 45px;
  .uom-container {
    .uom-heading {
      color: black;
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
  .modify-password-container {
    margin-top: 60px;
  }
}
