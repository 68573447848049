.add-new-button {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
}
.ant-table {
  line-height: 0.5;
}

.email-id {
  .ant-form-extra {
    font-size: 12px;
    font-style: italic;
    color: black;
  }
}
