.eachCol {
  padding: 5px;
  font-size: 15px;
  color: black;
}
.labelCol {
  text-align: right;
  font-size: 15px;
  color: black;
}
.category-heading {
  font-weight: bold;
  font-size: 18px;
}
.general-info {
  margin-top: 40px;
  border-bottom-width: 1;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
}
.physical-info {
  margin-top: 10px;
  border-bottom-width: 1;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
}
.highlighted-row {
  background: #d7eafb;
}
