.login-card {
  background-color: #f6f6f6;
  text-align: center;
  margin-top: 70px;
  border: 1px solid;
  width: 100%;
  height: 584px;
}

.upper-half-login {
  padding: 35px 30px;
}
.ant-card-body {
  padding: 24px 0px 0px 0px !important;
  zoom: 1;
}
.login-form {
  padding: 0px 40px 0px 40px !important;
  .ant-col {
    width: 100%;
  }
}

.reset-form-item {
  text-decoration: underline;
  outline: none !important;
  .reset-button {
    text-decoration: underline !important;
    font-size: 18px;
    border: none;
    background: none;
    outline: none !important;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    text-decoration: underline !important;
    background: none;
    border: none;
    outline: none !important;
  }

  .ant-btn[disabled]:hover,
  .ant-btn[disabled],
  .ant-btn[enabled] {
    text-decoration: underline !important;
    background: none;
    border: none;
    outline: none !important;
  }
}

.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none !important;
  content: '';
}
