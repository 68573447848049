.button-color {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-color:hover {
  background: #f3696f;
  color: white;
}
.button-color:active {
  background: #f3696f;
  color: white;
  border-color: #40a9ff;
}
.button-color:focus {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-color:visited {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-color:focus-within {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-delete {
  background: #1890ff;
  color: white;
  border-color: #ce0e2d;
}
.button-delete:hover {
  background: #f3696f !important;
  color: white;
}
.button-delete:active {
  background: #f3696f;
  color: white;
  border-color: #40a9ff;
}
.button-delete:focus {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-delete:visited {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
.button-delete:focus-within {
  background: #ce0e2d;
  color: white;
  border-color: #40a9ff;
}
