.spreadSheet-table-container {
  .file-name-label {
    font-size: 16px;
    padding: 10px 0px;
    color: black;

    span {
      padding-left: 5px;
      color: none;
      color: #004eff;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    .add-button {
      margin-right: 10px;
    }
    .cancel-button {
      margin-left: 10px;
    }
  }
}
