body {
  font-family: 'Open Sans', sans-serif;
}
.container {
  width: 100%;
}
.container-big {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}
.container-extra-big {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}
.container-full {
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.container-big {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

#nutSuppCompareTables {
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
}

.target-row-color {
  background-color: #ecffdc;
}
.acceptable-row-color {
  background-color: #dbeaff;
}
.row-color {
  background-color: #ffdfdf;
}
