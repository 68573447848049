.create-page-container {
  padding: 15px 35px;
  .breadcrumb {
    padding-bottom: 30px;
  }
  .input-fields {
    padding: 15px 150px;
    .ant-col-12 {
      display: flex;
      padding: 30px;
      align-items: center;
    }
  }
}
.save-forage-button {
  display: flex;
  justify-content: center;
}
.react-tabs__tab {
  font-size: 20px;
}
.add-heading {
  font-size: 18px;
  color: black;
  padding-left: 7px;
  margin-bottom: 1px !important;
}
.ant-form-item {
  display: flex;
}
.main-input-item {
  margin-top: 20px;
}
.select-item-name {
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-size: 17px;
  color: black;
}
