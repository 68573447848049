.farm-select {
  color: black;
  padding-right: 10px;
  font-size: 15px;
}
.help-text-select-farm {
  font-style: italic;
  padding-left: 35px;
  font-size: 12px;
}
.field {
  color: black;
  padding: 15px;
  .field-name {
    text-align: right;
    font-size: 15px;
    padding-right: 15px;
  }
  .field-value {
    font-size: 15px;
  }
}
