.uploadPhoto-inner-box {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 20px 35px;
}
.ant-modal-content {
  width: 672px;
}
.ant-modal-title {
  text-align: center;
}
.age-label {
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  padding-top: 8px;
}
.age-container {
  display: flex;
}
.upload-box {
  border: 1px solid;
  border-color: #d8d4d4;
  width: max-content;
  padding: 5px 5px;
  margin: 10px 0px;
}
.farm-data {
  .label {
    padding: 15px;
    .label-name {
      color: black;
      text-align: right;
      padding-right: 15px;
    }
  }
}
.age-form-item {
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '' !important;
  }
}
