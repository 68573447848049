.header-container {
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ce0e2d;

  .logo {
    padding-top: 5px;
    height: 60px;
    padding-right: 10px;
    background-color: #eaeaea;
    padding-left: 10px;
  }

  .menu-list {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
    .link {
      color: #ffffff;
    }
    .link-active {
      color: #ffffff;
      font-weight: bold;
      border-bottom: 2px solid #ffffff;
    }
  }

  .logout-container {
    display: flex;
    flex-direction: column;
    padding: 5px 21px;
    color: #ffffff;
    cursor: pointer;
  }
}

.link-menu {
  font-weight: 600;
}

.ant-dropdown-menu-item > a {
  color: #ce0e2d;
  font-weight: normal;
}
