.select-container {
  display: flex;
  .select-item-name {
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: black;
  }
}
.select-message {
  font-style: italic;
  padding-top: 5px;
}
.feed-heading {
  display: flex;
  width: 100%;
  .feed-name-code {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
      margin-bottom: 10px;
      color: black;
      span {
        color: #ca45ca;
        text-transform: capitalize;
        font-weight: none !important;
      }
    }
  }
}
.nutrients {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  .nutrient {
    display: flex;
    width: 342px;
    padding: 10px;
    .nutrient-name {
      display: flex;
      position: relative;
      min-height: 1px;
      width: 342px;
      justify-content: flex-end;
      color: black;
    }
    .nutrient-value {
      display: flex;
      position: relative;
      min-height: 1px;
      width: 342px;
      justify-content: flex-start;
      color: #ca45ca;
      padding-left: 4px;
    }
  }
}
.item-comp {
  font-size: 18px;
  color: black;
  padding-left: 7px;
  margin-bottom: 1px !important;
}
