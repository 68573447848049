.added-feed-item-list {
  font-size: 18px;
  color: black;
  padding-left: 7px;
  margin-bottom: 0px;
}

#nextbutton {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
#previousbutton {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.ant-modal-content {
  width: 95%;
  margin: auto;
}

.analyze-btn {
  white-space: normal;
  width: 100px;
  height: 50px;
  background: #6ff36f;
  color: black;
  border: 2px solid #129e12;
}
.analyze-btn:hover {
  text-decoration: none;
  background: #6ff36f;
  color: black;
  border: 2px solid #129e12;
}
