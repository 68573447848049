#root {
  height: 100%;
  .ration-analyzer-conatiner {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .next-previous-buttons {
    margin-bottom: 50px;
  }
}
