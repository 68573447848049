.ant-carousel .slick-slide {
  text-align: center;

  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.item {
  color: black;
  padding: 15px;
  .item-name {
    text-align: right;
    font-size: 15px;
    padding-right: 15px;
  }
  .item-value {
    font-size: 15px;
  }
}
.ril__caption {
  display: flex;
  justify-content: center;
}
.view-horse-photos {
  height: 260px;
  display: flex;
  align-items: center;
}
