#change-password-container {
  .ant-form-item-control {
    display: flex;
    align-items: center;

    .ant-form-explain {
      margin-left: 15px;
    }
  }
  .ant-input-affix-wrapper {
    width: 251px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}
